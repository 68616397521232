// import Script from 'next/script';
//
// const AccessibeScript = () => (
//   <>
//     {/* Preload script */}
//     <link
//       rel="preload"
//       href="https://acsbapp.com/apps/app/dist/js/app.js"
//       as="script"
//     />
//
//     {/* Load the script without blocking the main thread */}
//     <Script
//       id="accessibe-init"
//       src="https://acsbapp.com/apps/app/dist/js/app.js"
//       strategy="lazyOnload" // Ensure it executes after page load
//       onLoad={() => {
//         if (window?.acsbJS && typeof window.acsbJS.init === 'function') {
//           if (window?.acsbJS) {
//             window.acsbJS.init({
//               statementLink: '',
//               footerHtml: '',
//               hideMobile: false,
//               hideTrigger: false,
//               disableBgProcess: false,
//               language: 'en',
//               position: 'left',
//               leadColor: '#007b83',
//               triggerColor: '#007b83',
//               triggerRadius: '50%',
//               triggerPositionX: 'left',
//               triggerPositionY: 'bottom',
//               triggerIcon: 'people',
//               triggerSize: 'medium',
//               triggerOffsetX: 32,
//               triggerOffsetY: 32,
//               mobile: {
//                 triggerSize: 'small',
//                 triggerPositionX: 'right',
//                 triggerPositionY: 'center',
//                 triggerOffsetX: 16,
//                 triggerOffsetY: 0,
//                 triggerRadius: '50%',
//               },
//             });
//           }
//         }
//       }}
//     />
//   </>
// );

import { useEffect } from 'react';
import { SCRIPT_LOAD_TIME_ACS } from '@constants/base';

const AccessibeScript = () => {
  useEffect(() => {
    // Delay script loading by 4 seconds after the page has loaded
    const delayLoadingScript = () => {
      setTimeout(() => {
        const script = document.createElement('script');
        script.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
        script.async = true;
        script.onload = () => {
          if (window?.acsbJS && typeof window.acsbJS.init === 'function') {
            window.acsbJS.init({
              statementLink: '',
              footerHtml: '',
              hideMobile: false,
              hideTrigger: false,
              disableBgProcess: false,
              language: 'en',
              position: 'left',
              leadColor: '#007b83',
              triggerColor: '#007b83',
              triggerRadius: '50%',
              triggerPositionX: 'left',
              triggerPositionY: 'bottom',
              triggerIcon: 'people',
              triggerSize: 'medium',
              triggerOffsetX: 32,
              triggerOffsetY: 32,
              mobile: {
                triggerSize: 'small',
                triggerPositionX: 'right',
                triggerPositionY: 'center',
                triggerOffsetX: 16,
                triggerOffsetY: 0,
                triggerRadius: '50%',
              },
            });
          }
        };
        document.body.appendChild(script);
      }, SCRIPT_LOAD_TIME_ACS); // 4-second delay
    };

    // Trigger delay after page load
    if (typeof window !== 'undefined') {
      window.addEventListener('load', delayLoadingScript);
    }

    // Cleanup event listener
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('load', delayLoadingScript);
      }
    };
  }, []);

  return null; // No need to render anything in the JSX
};

export default AccessibeScript;
